// 刚刚封装的axios
import requests from "./request";

// 获取节点列表
export function getNodeList(data) {
  return requests.requests({
    url: "/server/api/v1/nodes",
    method: "post",
    data,
  });
}

// 查看节点详情
export function getNodeDetails(id) {
  return requests.requests({
    url: `/server/api/v1/node/${id}`,
    method: "get",
  });
}

// 查看用户节点详情
export function getUserNodeDetails(id) {
  return requests.requests({
    url: `/server/api/v1/user_node_info/${id}`,
    method: "get",
  });
}

// 疏散节点
export function evacuateNode(data) {
  return requests.requests({
    url: `/server/api/v1/node_migrate`,
    method: "put",
    data,
  });
}

// 疏散用户节点
export function evacuateUserNode(data) {
  return requests.requests({
    url: `/server/api/v1/user_node_migrate`,
    method: "put",
    data,
  });
}

// 停止用户服务
export function stopUserServe(data) {
  return requests.requests({
    url: `/server/api/v1/stop_service`,
    method: "put",
    data,
  });
}
