import axios from "axios";
// import qs from "qs";
const requests = axios.create({
  // baseURL: process.env.VUE_APP_API,
  // baseURL: 'http://10.57.62.96',
  baseURL: "http://120.53.118.130:5000",
  timeout: 6000,
});

// // http request 拦截器
// 错误处理函数
const err = (error) => {
  if (error.response) {
    const data = error.response.data;
    // const token = Vue.ls.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      console.log("data.message || data.msg", data.message || data.msg);
      // Notify({ type: 'danger', message: data.message || data.msg });
    }
    if (error.response.status === 401) {
      console.log("message", "你没有权限");
      console.log("error.response", error.response.data.result);
      window.location.href = error.response.data.result;
      // Notify({ type: 'danger', message: '你没有权限。' });
      // if (token) {
      //   store.dispatch('Logout').then(() => {
      //     setTimeout(() => {
      //       window.location.reload()
      //     }, 1500)
      //   })
      // }
    }
  }
  return Promise.reject(error);
};
// request interceptor(请求拦截器)
requests.interceptors.request.use((config) => {
  //   const token = Vue.ls.get(ACCESS_TOKEN)
  // const token = localStorage.getItem('ticket')
  // const token = localStorage.getItem('')
  // if (token) {
  //     config.headers['token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  // }
  return config;
}, err);
// response interceptor（接收拦截器）
requests.interceptors.response.use((response) => {
  const res = response.data;
  if (response.status !== 0 && response.status !== 200) {
    console.log("res.message || res.msg", res.message || res.msg);
    //   Notify({ type: 'danger', message: res.message||res.msg });
    // 401:未登录;
    if (
      response.status === 401 ||
      response.status === 403 ||
      response.status === 999
    ) {
      console.log("message", "请登录");
      // Notify({ type: 'danger', message: '请登录'});
    }
    // return Promise.reject('error')
  } else {
    return res;
  }
}, err);
export default {
  requests,
};
